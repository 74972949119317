<template>
  <nav>
    <template v-for="item in fastMenu" :key="item">
      <a :href="item.router_link" @click="saveRotaFavorita(item)" v-if="item.router_link" :target="item.tipo_url">
        {{ item.name }}
      </a>
      <a :href="item.path" @click="saveRotaFavorita(item)" v-else-if="item.path" :target="item.tipo_url">
        {{ item.roleName }}
      </a>
      <a href="#" @click.prevent="goToRoute(item)" v-else-if="item.numeroDashboard">
        {{ item.name }}
      </a>
    </template>
  </nav>
</template>

<script>
import UserService from '@/services/user.service.js'

export default {
  data () {
    return {
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      fastMenu: []
    }
  },
  mounted () {
    this.getMenuFavoritos()
  },
  methods: {
    getMenuFavoritos () {
      UserService.getMenuFavoritos(this.currentUser.idPessoa).then(
        response => {
          console.log("getMenuFavoritos:", response.data)
          this.fastMenu = response.data
        },
        () => {

        }
      )
    },
    saveRotaFavorita ( route ) {
      const idPessoa = this.currentUser.idPessoa
      const data = {
        idPessoa: idPessoa,
        idRole: null,
        idPermission: route.id
      }

      UserService.saveRotaFavorita(data).then(
        response => {
          console.log("saveRotaFavorita:", response.data)
        }
      )
    },
    goToRoute(item) {
      if ( item.externo == 1 && item.numeroDashboard ) {
        // const query = this.getQueryStringParams(item.router_link)
        this.$router.push({name: 'Painel', params: { id: item.numeroDashboard }, query: {path: item.router_link} })
      }

      this.saveRotaFavorita(item)
    }
  }
}
</script>

<style lang="scss" scoped>
  nav {
    display: flex; align-items: center; align-content: center;
    gap: $mg_mid; margin-left: $hmg;

    a {
      // &:first-child {
      //   margin-left: $hmg_mid
      // }
      
      // margin-left: $mg_mid;

      &:hover {
        color: $color-primary
      }
    }

    @media screen and ( max-width: 1024px ) {
      flex-direction: column;
      gap: $hmg_mini;
      padding-top: $mg_mini;
      
      a {
        margin-bottom: $hmg_mini;
        padding: $hmg_mini $hmg_mid;
        width: 100%;
      }
    }
  }
</style>