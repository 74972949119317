<template>
  <controller>
    <page-header title="AgendaEdu - Canais">
      <template v-slot:side>
        <a href="#" @click.prevent="toggleNew(false)" class="btn btn-secondary" v-if="action">
          Sair
        </a>
        <a href="#" @click.prevent="toggleNew(true)" class="btn btn-primary" v-else>
          Novo Canal
        </a>
      </template>
    </page-header>
    <section>
      <canal v-if="novo || editar" :id="id" :action="action" @onSave="updateList()"/>
      <canais ref="canais" v-else/>
    </section>
  </controller>
</template>

<script>
import Canais from '@/components/agendaedu/canais/template/Canais.vue'
import Canal from '@/components/agendaedu/canais/template/Canal.vue'

export default {
  props: {
    action: {
      type: String,
    },
    id: {
      type: [String, Number]
    }
  },
  components: {
    Canais, Canal
  },
  watch: {
    action ( value ) {
      if ( value ) {
        this[value] = true
      }
    }
  },
  data () {
    return {
      novo: false,
      editar: false
    }
  },
  mounted () {
    this.novo = false
    this.editar = false

    this[this.action] = true
  },
  methods: {
    toggleNew ( state ) {
      this.editar = false
      this.novo = false

      if ( state ) {
        this.$router.push({ name: 'AgendaEdu Canais', params: { action: 'novo' } }) 
      } else {
        this.$router.push({ name: 'AgendaEdu Canais', params: {} }) 
      }
    },
    updateList () {
      this.$router.push({ name: 'AgendaEdu Canais', params:{} })
      this.$refs.canais.getCanais()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>