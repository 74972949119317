import axios from 'axios'
import authHeader from './auth-header'

class AgendaEduService {

  getCanais () {
    return axios.get(process.env.VUE_APP_API_URL + 'agendaedu/integracao/canais/listar', { headers: authHeader() })
  }
  
  getCanal ( idCanal ) {
    return axios.get(process.env.VUE_APP_API_URL + 'agendaedu/integracao/canais/ver/' + idCanal, { headers: authHeader() })
  }

  getTurmas () {
    return axios.get(process.env.VUE_APP_API_URL + 'agendaedu/turmas/cursos/2024', { headers: authHeader() })
  }

  saveCanal ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'agendaedu/integracao/canais/criar', data, { headers: authHeader() })
  }

  deleteCanal ( idCanal ) {
    return axios.delete(process.env.VUE_APP_API_URL + 'agendaedu/integracao/canais/deletar/' + idCanal, { headers: authHeader() })
  }
}

export default new AgendaEduService()