<template>
  <section>
    <form-spinner v-if="loading"/>
    <div class="frame canais" v-else>
      <div v-if="canais.length == 0" class="frame micro empty">
        <fa :icon="['fat', 'message-plus']" class="ic"/>
        <h2>Você ainda não tem canais criados</h2>
        <router-link :to="{ name: 'AgendaEdu Canais', params: { action: 'novo'} }" class="btn btn-secondary">
          Criar novo
        </router-link>
      </div>
      <template v-else>
        <header>
          <div>
            <input-control v-model="query" placeholder="O que você procura?" search preventSearchButton/>
          </div>
        </header>
        <div class="fancy-list" >
          <div class="fancy-list-header">
            <span></span>
            <span>ID</span>
            <span>Nome</span>
            <span>Descrição</span>
            <span>Última Edição</span>
            <span></span>
          </div>
          <ul>
            <li v-for="canal in canais" :key="canal">
              <card :boxed="true" overflow="visible" :listed="false" hover>
                <div class="fancy-list-item">
                  <header>
                    <div>
                      <span class="thumb" :style="{ backgroundImage: `url('${thumbUrl}${canal.iconCanal}.svg')` }"></span>
                    </div>
                    <span>
                      {{ canal.idCanalAgendaEdu }}
                    </span>
                    <span>
                      {{ canal.nomeCanal }}
                    </span>
                    <span>
                      {{ canal.descricaoCanal }}
                    </span>
                    <span>
                      {{ canal.editedAt ? parseName(canal.editedPor) : parseName(canal.criadoPor) }} - {{ canal.editedAt ? formatDateTime(canal.editedAt) : canal.createdAt }}
                    </span>
                    <span class="actions">
                      <a href="#" @click.prevent="toggleRemove(canal)">
                        <fa :icon="['far', 'trash-can']" />
                      </a>
                    </span>
                    <span class="toggleIconWrap">
                      <router-link :to="{ name: 'AgendaEdu Canais', params:{ action: 'editar', id: canal.idCanal } }">
                        <fa :icon="['far', 'arrow-right-long']" />
                      </router-link>
                    </span>
                  </header>
                </div>
              </card>
            </li>
          </ul>
        </div>
      </template>
      <confirm 
        :backdrop="false"
        class="confirm"
        ref="confirm"
      ></confirm>
    </div> 
  </section>
</template>

<script>
import AgendaEduService from '@/services/agendaedu.service.js'
import  { formatDateTime } from '@/services/utils.service.js'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    FormSpinner
  },
  data () {
    return {
      loading: false,
      canais: [],
      thumbUrl: 'https://static-dev.agendaedu.com/icon_messages/',
      thumbs: [
        {
          label: 'Autorização',
          slug: 'authorization'
        },
        {
          label: 'Café',
          slug: 'coffee'
        },
        {
          label: 'Coordenador',
          slug: 'coordinators'
        },
        {
          label: 'Financeiro',
          slug: 'financer'
        },
        {
          label: 'Geral',
          slug: 'general'
        },
        {
          label: 'Suporte',
          slug: 'helpcenter'
        },
        {
          label: 'Biblioteca',
          slug: 'library'
        },
        {
          label: 'Achados e Perdidos',
          slug: 'lostandfound'
        },
        {
          label: 'Cuidados',
          slug: 'nursing'
        },
        {
          label: 'Nutrição',
          slug: 'nutrition'
        },
        {
          label: 'Ouvidoria',
          slug: 'ombudsman'
        },
        {
          label: 'Direção',
          slug: 'principal'
        },
        {
          label: 'Psicologia',
          slug: 'psychology'
        },
        {
          label: 'Agendamento',
          slug: 'scheduling'
        },
        {
          label: 'Secretaria',
          slug: 'secretary'
        },
        {
          label: 'Professor',
          slug: 'teacher'
        },
        {
          label: 'Tesouraria',
          slug: 'treasury'
        },
        {
          label: 'Armazém',
          slug: 'warehouse'
        }
      ],
      query: null,
    }
  },
  mounted () {
    
    this.getCanais()
  },
  methods: {
    getCanais () {
      this.loading = true
      AgendaEduService.getCanais().then(
        response => {
          this.canais = response.data
          this.loading = false
          console.log("canais:", this.canais)
        },
        error => {
          console.error(error)
        }
      )
    },
    formatDateTime ( date ) {
      return formatDateTime(date)
    },
    parseName ( name ) {
      return name.substr(0,12) + '...'
    },
    toggleRemove ( canal ) {
      this.$refs.confirm.run({
        message: "Você tem certeza que deseja remover este canal? Lembre-se que se você remover este canal não terá mais acesso as conversas e tickets.",
        confirmButton: "Remover",
        denyButton: "Cancelar",
        callback: this.deleteCanal,
        data: canal,
        backdropColor: 'blur'
      })
    },
    deleteCanal ( response, canal ) {
      if ( response ) {
        this.loading = true
        AgendaEduService.deleteCanal( canal.idCanal ).then(
          response => {
            if ( response.status == 200 ) {
              this.getCanais()
            }
          },
          error => {
            this.loading = false
            console.error(error)
          }
        )
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
  .canais {
    >header {
      padding: $mg 0 $hmg_mid;

      >div {
        max-width: 30%;
      }
    } 
  }
  .fancy-list {
    padding: 0;
    margin: $hmg 0 $mg;

    .fancy-list-header {
      padding: 0 $hmg; margin-bottom: $mg_mini;
      display: grid;
      width: 100%;
      align-content: stretch; align-items: center;
      grid-template-columns: 50px 80px 1.5fr minmax(300px, 3fr) 2fr 40px 40px;
      font-size: $font-size-small;
      font-weight: bold;
    }
    
    .toggleIconWrap {
      font-size: 18px;
      color: $color-primary;
      text-align: right;
      line-height: 1;
    }

    .actions {
      font-size: 14px;
      &:hover {
        color: $color-primary
      }
    }

    ul {
      padding: 0; margin: 0;
      
      li {
        padding: 0;
        list-style: none;
        margin: 0 0 $mg_mini 0;

        .thumb {
          display: block; width: 34px; height: 34px;
          border-radius: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }

        .fancy-list-item {
          >header {
            display: grid;
            width: 100%;
            align-content: stretch; align-items: center;
            grid-template-columns: 50px 80px 1.5fr minmax(300px, 3fr) 2fr 40px 40px;
          }
        }
        
        .toggle {
          display: inline-block; width: 18px; height: 18px; border-radius: 100%;
          border: 1px solid $color-fade; margin-right: $hmg_small;
          transform-origin: center; @extend %transition_4e;
          position: relative; pointer-events: none;
          &:after {
            content: ""; display: block;
            border-radius: 100%; background-color: $color-primary;
            width: 10px; height: 10px; transform-origin: top center;
            transform: scale(0) translateY(-50%);
            position: absolute; top: 50%;
            left: 0; right: 0; margin: auto;
            @extend %transition_4e;
          }

          &.active {
            background-color: $color-bg;
            pointer-events: all;
            &:after {
              transform: scale(1) translateY(-50%);
            }
          }
        }
      }
    }
  }

  .empty { 
    text-align: center;
    margin-top: $mg_large !important;
    
    .ic {
      font-size: 64px;
      margin-bottom: $hmg_mid
    }
    .btn {
      margin-top: $hmg_mid
    }
  }
</style>